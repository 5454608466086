import { SuspenseWithSpinner, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtom, useSetAtom } from 'jotai'

import { createRestAtom, currentWorkTimeModeAtom } from '../../atoms/restaraunts/createRestAtom'

import { ModalCreateRestInfo } from './ModalCreateRestInfo'
import { ModalCreateRestInfoFooter } from './ModalCreateRestInfoFooter'
import { ModalCreateRestWorkTime } from './ModalCreateRestWorkTime'
import { ModalCreateRestIntegration } from './ModalCreateRestIntegration'
import { CreateRestFormData, CreateRestSchema } from './CreateRestSchema'
import { useState } from 'react'
import { ModalTypeOrder } from './ModalTypeOrder'

export const ModalCreateRest = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useAtom(currentWorkTimeModeAtom)
    const methods = useForm<CreateRestFormData>({
        resolver: zodResolver(CreateRestSchema),
        defaultValues: {
            orderMethods: [],
            deliveryEnabled: true,
            terminalEnabled: false,
            pickupEnabled: false,
            tableEnabled: false,
            delivery: {
                comission: 0,
                serviceFee: 0,
                isOurCouriers: false,
                minimumPrice: 0,
                paymentTypes: [],
            },
            pickup: {
                comission: 0,
                serviceFee: 0,
                minimumPrice: 0,
                paymentTypes: [],
            },
            terminal: {
                comission: 0,
                serviceFee: 0,
                minimumPrice: 0,
                paymentTypes: [],
            },
            table: {
                comission: 0,
                serviceFee: 0,
                minimumPrice: 0,
                paymentTypes: [],
                tableCount: 0,
            },
        },
    })

    const createRest = useSetAtom(createRestAtom)

    console.log(methods.formState)

    const onSubmit = async (data: CreateRestFormData) => {
        if (data.deliveryEnabled) {
            if (
                data.delivery?.paymentTypes?.length &&
                data.delivery?.comission &&
                data.delivery?.minimumPrice &&
                data.delivery?.serviceFee
            ) {
            } else {
                return
            }
        }

        if (data.pickupEnabled) {
            if (data.pickup?.paymentTypes?.length && data.pickup?.comission && data.pickup?.minimumPrice && data.pickup?.serviceFee) {
            } else {
                return
            }
        }

        if (data.terminalEnabled) {
            if (
                data.terminal?.paymentTypes?.length &&
                data.terminal?.comission &&
                data.terminal?.minimumPrice &&
                data.terminal?.serviceFee
            ) {
            } else {
                return
            }
        }

        if (data.tableEnabled) {
            if (
                data.table?.paymentTypes?.length &&
                data.table?.comission &&
                data.table?.minimumPrice &&
                data.table?.serviceFee &&
                data.table?.tableCount
            ) {
            } else {
                return
            }
        }

        setLoading(true)
        // @ts-ignore
        await createRest({ ...data, checkbox: mode })
        setLoading(false)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Tabs
                    tabs={[
                        {
                            tabOption: {
                                tabName: 'Инфо',
                            },
                            child: (
                                <SuspenseWithSpinner>
                                    <ModalCreateRestInfo />
                                </SuspenseWithSpinner>
                            ),
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Время работы',
                            },
                            child: <ModalCreateRestWorkTime />,
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Настройки интеграции',
                            },
                            child: <ModalCreateRestIntegration />,
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Тип заказа',
                            },
                            child: <ModalTypeOrder />,
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                    ]}
                />
            </form>
        </FormProvider>
    )
}
