import { ChangeEvent, FC } from 'react'
import { AppSelect, Checkbox, ErrorText, RegularText, Switch, TextInput } from '../../components'
import { useFormContext } from 'react-hook-form'

export const ModalTypeOrder: FC = () => {
    const { watch, setValue } = useFormContext<any>()

    const changeDelivery = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('delivery', { ...watch('delivery'), [e.target.name]: Number(e.target.value) })
    }

    const changePickup = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('pickup', { ...watch('pickup'), [e.target.name]: Number(e.target.value) })
    }

    const changeTerminal = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('terminal', { ...watch('terminal'), [e.target.name]: Number(e.target.value) })
    }

    const changeTable = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('table', { ...watch('table'), [e.target.name]: Number(e.target.value) })
    }

    const handleDeliv = ev => {
        const { name } = ev.target
        let currentVal = watch('delivery').paymentTypes
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('delivery', { ...watch('delivery'), paymentTypes: currentVal })
    }

    const handlePickup = ev => {
        const { name } = ev.target
        let currentVal = watch('pickup').paymentTypes
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('pickup', { ...watch('pickup'), paymentTypes: currentVal })
    }

    const handleTerminal = ev => {
        const { name } = ev.target
        let currentVal = watch('terminal').paymentTypes
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('terminal', { ...watch('terminal'), paymentTypes: currentVal })
    }

    const handleTable = ev => {
        const { name } = ev.target
        let currentVal = watch('table').paymentTypes
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('table', { ...watch('table'), paymentTypes: currentVal })
    }

    return (
        <div className={'flex'} style={{ marginTop: 10, gap: 22, justifyContent: 'center', padding: '10px' }}>
            <div
                style={{
                    opacity: watch('deliveryEnabled') ? 1 : 0.5,
                    border: '1px solid #BABABA',
                    borderRadius: 8,
                    padding: '10px',
                    width: 280,
                }}>
                <div style={{ display: 'flex' }}>
                    <Switch checked={watch('deliveryEnabled')} onClick={() => setValue('deliveryEnabled', !watch('deliveryEnabled'))} />{' '}
                    <span style={{ marginLeft: 10 }}>Доставка</span>
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Комиссия с продаж</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'comission'}
                            onChange={changeDelivery}
                            value={watch('delivery').comission || ''}
                            disabled={!watch('deliveryEnabled')}
                            style={{ width: 188 }}
                        />
                        %
                    </div>
                    {watch('deliveryEnabled')
                        ? !watch('delivery').comission && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Сервисный сбор</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'serviceFee'}
                            onChange={changeDelivery}
                            value={watch('delivery').serviceFee || ''}
                            disabled={!watch('deliveryEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('deliveryEnabled')
                        ? !watch('delivery').serviceFee && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Мин. сумма заказа</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            name={'minimumPrice'}
                            type={'number'}
                            onChange={changeDelivery}
                            value={watch('delivery').minimumPrice || ''}
                            disabled={!watch('deliveryEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('deliveryEnabled')
                        ? !watch('delivery').minimumPrice && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <RegularText>Тип оплаты</RegularText>
                    <Checkbox
                        disabled={!watch('deliveryEnabled')}
                        name='cash'
                        onChange={handleDeliv}
                        checked={(watch('delivery').paymentTypes || []).includes('cash')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Наличные</span>
                    <Checkbox
                        disabled={!watch('deliveryEnabled')}
                        name='online'
                        onChange={handleDeliv}
                        checked={(watch('delivery').paymentTypes || []).includes('online')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Безналичные</span>
                </div>
                {watch('deliveryEnabled')
                    ? !watch('delivery').paymentTypes?.length && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                    : null}
                <div style={{ marginTop: '5px' }}>
                    Тип курьеров
                    <div style={{ width: 150 }}>
                        <AppSelect
                            value={[
                                { value: true, label: 'Locali' },
                                { value: false, label: 'Свои' },
                            ].find(item => watch('delivery').isOurCouriers === item.value)}
                            //@ts-ignore
                            onChange={({ value }) =>
                                setValue('delivery', {
                                    ...watch('delivery'),
                                    isOurCouriers: value,
                                })
                            }
                            options={[
                                { value: true, label: 'Locali' },
                                { value: false, label: 'Свои' },
                            ]}
                            className={'w-1'}
                        />
                    </div>
                </div>
            </div>

            <div
                style={{
                    opacity: watch('pickupEnabled') ? 1 : 0.5,
                    border: '1px solid #BABABA',
                    borderRadius: 8,
                    padding: '10px',
                    width: 280,
                }}>
                <div style={{ display: 'flex' }}>
                    <Switch checked={watch('pickupEnabled')} onClick={() => setValue('pickupEnabled', !watch('pickupEnabled'))} />{' '}
                    <span style={{ marginLeft: 10 }}>Самовывоз</span>
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Комиссия с продаж</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'comission'}
                            onChange={changePickup}
                            value={watch('pickup').comission || ''}
                            disabled={!watch('pickupEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        %
                    </div>
                    {watch('pickupEnabled')
                        ? !watch('pickup').comission && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Сервисный сбор</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'serviceFee'}
                            onChange={changePickup}
                            value={watch('pickup').serviceFee || ''}
                            disabled={!watch('pickupEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('pickupEnabled')
                        ? !watch('pickup').serviceFee && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Мин. сумма заказа</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'minimumPrice'}
                            onChange={changePickup}
                            value={watch('pickup').minimumPrice || ''}
                            disabled={!watch('pickupEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('pickupEnabled')
                        ? !watch('pickup').minimumPrice && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <RegularText>Тип оплаты</RegularText>
                    <Checkbox
                        disabled={!watch('pickupEnabled')}
                        name='cash'
                        onChange={handlePickup}
                        checked={(watch('pickup').paymentTypes || []).includes('cash')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Наличные</span>
                    <Checkbox
                        disabled={!watch('pickupEnabled')}
                        name='online'
                        onChange={handlePickup}
                        checked={(watch('pickup').paymentTypes || []).includes('online')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Безналичные</span>
                </div>
                {watch('pickupEnabled')
                    ? !watch('pickup').paymentTypes?.length && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                    : null}
            </div>

            <div
                style={{
                    opacity: watch('terminalEnabled') ? 1 : 0.5,
                    border: '1px solid #BABABA',
                    borderRadius: 8,
                    padding: '10px',
                    width: 280,
                }}>
                <div style={{ display: 'flex' }}>
                    <Switch checked={watch('terminalEnabled')} onClick={() => setValue('terminalEnabled', !watch('terminalEnabled'))} />{' '}
                    <span style={{ marginLeft: 10 }}>Забрать на кассе</span>
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Комиссия с продаж</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'comission'}
                            onChange={changeTerminal}
                            value={watch('terminal').comission || ''}
                            disabled={!watch('terminalEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        %
                    </div>
                    {watch('terminalEnabled')
                        ? !watch('terminal').comission && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Сервисный сбор</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'serviceFee'}
                            onChange={changeTerminal}
                            value={watch('terminal').serviceFee || ''}
                            disabled={!watch('terminalEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('terminalEnabled')
                        ? !watch('terminal').serviceFee && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Мин. сумма заказа</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'minimumPrice'}
                            onChange={changeTerminal}
                            value={watch('terminal').minimumPrice || ''}
                            disabled={!watch('terminalEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('terminalEnabled')
                        ? !watch('terminal').minimumPrice && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <RegularText>Тип оплаты</RegularText>
                    <Checkbox
                        disabled={!watch('terminalEnabled')}
                        name='cash'
                        onChange={handleTerminal}
                        checked={(watch('terminal').paymentTypes || []).includes('cash')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Наличные</span>
                    <Checkbox
                        disabled={!watch('terminalEnabled')}
                        name='online'
                        onChange={handleTerminal}
                        checked={(watch('terminal').paymentTypes || []).includes('online')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Безналичные</span>
                </div>
                {watch('terminalEnabled')
                    ? !watch('terminal').paymentTypes?.length && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                    : null}
            </div>

            <div
                style={{
                    opacity: watch('tableEnabled') ? 1 : 0.5,
                    border: '1px solid #BABABA',
                    borderRadius: 8,
                    padding: '10px',
                    width: 280,
                }}>
                <div style={{ display: 'flex' }}>
                    <Switch checked={watch('tableEnabled')} onClick={() => setValue('tableEnabled', !watch('tableEnabled'))} />{' '}
                    <span style={{ marginLeft: 10 }}>Принести к столу</span>
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Комиссия с продаж</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'comission'}
                            onChange={changeTable}
                            value={watch('table').comission || ''}
                            disabled={!watch('tableEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                </div>
                {watch('tableEnabled')
                    ? !watch('table').comission && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                    : null}
                <div style={{ marginTop: '5px' }}>
                    <div>Сервисный сбор</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'serviceFee'}
                            onChange={changeTable}
                            value={watch('table').serviceFee || ''}
                            disabled={!watch('tableEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        %
                    </div>
                    {watch('tableEnabled')
                        ? !watch('table').serviceFee && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <div>Мин. сумма заказа</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'minimumPrice'}
                            onChange={changeTable}
                            value={watch('table').minimumPrice || ''}
                            disabled={!watch('tableEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        ₽
                    </div>
                    {watch('tableEnabled')
                        ? !watch('table').minimumPrice && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
                <div style={{ marginTop: '5px' }}>
                    <RegularText>Тип оплаты</RegularText>
                    <Checkbox
                        disabled={!watch('tableEnabled')}
                        name='cash'
                        onChange={handleTable}
                        checked={(watch('table').paymentTypes || []).includes('cash')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Наличные</span>
                    <Checkbox
                        disabled={!watch('tableEnabled')}
                        name='online'
                        onChange={handleTable}
                        checked={(watch('table').paymentTypes || []).includes('online')}
                    />{' '}
                    <span style={{ marginLeft: 30 }}>Безналичные</span>
                </div>
                {watch('tableEnabled')
                    ? !watch('table').paymentTypes?.length && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                    : null}
                <div style={{ marginTop: '5px' }}>
                    <div>Количество столов</div>
                    <div style={{ display: 'flex', gap: '4px' }}>
                        <TextInput
                            type={'number'}
                            name={'tableCount'}
                            onChange={changeTable}
                            value={watch('table').tableCount || ''}
                            disabled={!watch('tableEnabled')}
                            style={{ width: 188 }}
                        />{' '}
                        шт
                    </div>
                    {watch('tableEnabled')
                        ? !watch('table').tableCount && <ErrorText style={{ marginTop: 0 }} error={'Запоните это поле'} />
                        : null}
                </div>
            </div>
        </div>
    )
}
