import { Spinner, SuspenseWithSpinner, Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { Suspense, useEffect, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { editAllDaysAtom, editRestAtom, editWorkTimeAtom, editWorkTimeModeAtom } from 'src/atoms/restaraunts/editRestAtom'

import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import ErrorBoundary from '../../components/ErrorBoundary'
import { getAddressFromObject } from '../../utils/getAddressFromObject'

import { ModalEditRestInfo } from './ModalEditRestInfo'
import { ModalEditRestInfoFooter } from './ModalEditRestInfoFooter'
import { ModalEditRestWorkTime } from './ModalEditRestWorkTime'
import { ModalEditRestIntegration } from './ModalEditRestIntegration'
import { ModalEditRestOrders } from './ModalEditRestOrders'
import { ModalEditRestStat } from './ModalEditRestStat'
import { EditRestFormData, EditRestSchema } from './EditRestSchema'
import { useAtom } from 'jotai/index'
import { createAllDaysAtom } from '../../atoms/restaraunts/createRestAtom'
import { ModalEditTypeOrder } from './ModalEditTypeOrder'

export const InfoModalRest = () => {
    const [loading, setLoading] = useState(false)
    const editRest = useSetAtom(editRestAtom)
    const currentRest = useAtomValue(currentRestAtom)
    const setEditWorkTime = useSetAtom(editWorkTimeAtom)
    const [mode, setMode] = useAtom(editWorkTimeModeAtom)
    const setAllDays = useSetAtom(editAllDaysAtom)

    const methods = useForm<EditRestFormData>({
        defaultValues: {
            orderMethods: [],
        },
    })

    const onSubmit = async (data: EditRestFormData) => {
        if (data.deliveryEnabled) {
            if (
                data.delivery?.paymentTypes?.length &&
                data.delivery?.comission &&
                data.delivery?.minimumPrice &&
                data.delivery?.serviceFee
            ) {
            } else {
                return
            }
        }

        if (data.pickupEnabled) {
            if (data.pickup?.paymentTypes?.length && data.pickup?.comission && data.pickup?.minimumPrice && data.pickup?.serviceFee) {
            } else {
                return
            }
        }

        if (data.terminalEnabled) {
            if (
                data.terminal?.paymentTypes?.length &&
                data.terminal?.comission &&
                data.terminal?.minimumPrice &&
                data.terminal?.serviceFee
            ) {
            } else {
                return
            }
        }

        if (data.tableEnabled) {
            if (
                data.table?.paymentTypes?.length &&
                data.table?.comission &&
                data.table?.minimumPrice &&
                data.table?.serviceFee &&
                data.table?.tableCount
            ) {
            } else {
                return
            }
        }

        setLoading(true)
        // @ts-ignore
        await editRest({ ...data, checkbox: mode, order_region: currentRest?.order_region })
        setLoading(false)
    }

    const onInvalid = reason => {
        console.log(reason)
    }
    useEffect(() => {
        if (currentRest) {
            methods.setValue('group_id', currentRest.group_id)
            methods.setValue('email', currentRest.email ?? undefined)
            methods.setValue('restName', currentRest.restName)
            methods.setValue('servType', currentRest.servType === 'iiko')
            methods.setValue('kitchenType', currentRest.kitchenType ?? undefined)
            methods.setValue('restType', currentRest.restType ?? undefined)
            methods.setValue('tab_number', currentRest.tab_number ?? undefined)
            methods.setValue('email', currentRest.email ?? undefined)
            methods.setValue('website', currentRest.website ?? undefined)
            methods.setValue('paymentMethods', currentRest.paymentMethods || [])
            methods.setValue('isOurCouriers', currentRest.isOurCouriers ? 'Lokali' : ('Свои' ?? undefined))
            methods.setValue('manager', currentRest.manager ?? undefined)
            methods.setValue('apiKey', currentRest.apiKey ?? undefined)
            methods.setValue('apiLogin', currentRest.apiLogin ?? undefined)
            methods.setValue('login', currentRest.login ?? undefined)
            methods.setValue('password', currentRest.password ?? undefined)
            methods.setValue('restPercent', currentRest.restPercent ?? undefined)
            methods.setValue('city', getAddressFromObject(currentRest.address) ?? undefined)
            methods.setValue('latitude', currentRest.latitude ?? undefined)
            methods.setValue('longitude', currentRest.longitude ?? undefined)
            // methods.setValue('house', currentRest.address.house ?? undefined);
            // methods.setValue('street', currentRest.address.street ?? undefined);
            // methods.setValue('corpus', currentRest.address.corpus ?? undefined);
            methods.setValue('phoneNumber', currentRest.phoneNumber ?? undefined)
            methods.setValue('whatsApp', currentRest.whatsApp ?? undefined)
            methods.setValue('telegram', currentRest.telegram ?? undefined)
            methods.setValue('cashPaymentKind', currentRest.cashPaymentKind ?? undefined)
            methods.setValue('cashPaymentId', currentRest.cashPaymentId ?? undefined)
            methods.setValue('onlinePaymentKind', currentRest.onlinePaymentKind ?? undefined)
            methods.setValue('onlinePaymentId', currentRest.onlinePaymentId ?? undefined)
            methods.setValue('orderMethods', currentRest.orderMethods || [])
            methods.setValue('organizationId', currentRest.organizationId ?? undefined)
            methods.setValue('terminalgroupId', currentRest.terminalgroupId ?? undefined)

            methods.setValue('deliveryEnabled', currentRest?.deliveryEnabled || false)
            methods.setValue('terminalEnabled', currentRest?.terminalEnabled || false)
            methods.setValue('tableEnabled', currentRest?.tableEnabled || false)
            methods.setValue('pickupEnabled', currentRest?.pickupEnabled || false)

            methods.setValue('delivery', {
                comission: currentRest?.delivery?.comission || 0,
                serviceFee: currentRest?.delivery?.serviceFee || 0,
                isOurCouriers: currentRest?.delivery?.isOurCouriers || false,
                minimumPrice: currentRest?.delivery?.minimumPrice || 0,
                paymentTypes: currentRest?.delivery?.paymentTypes || [],
            })
            methods.setValue('terminal', {
                comission: currentRest?.terminal?.comission || 0,
                serviceFee: currentRest?.terminal?.serviceFee || 0,
                minimumPrice: currentRest?.terminal?.minimumPrice || 0,
                paymentTypes: currentRest?.terminal?.paymentTypes || [],
            })
            methods.setValue('pickup', {
                comission: currentRest?.pickup?.comission || 0,
                serviceFee: currentRest?.pickup?.serviceFee || 0,
                minimumPrice: currentRest?.pickup?.minimumPrice || 0,
                paymentTypes: currentRest?.pickup?.paymentTypes || [],
            })
            methods.setValue('table', {
                comission: currentRest?.table?.comission || 0,
                serviceFee: currentRest?.table?.serviceFee || 0,
                minimumPrice: currentRest?.table?.minimumPrice || 0,
                paymentTypes: currentRest?.table?.paymentTypes || [],
                tableCount: currentRest?.table?.tableCount || 0,
            })

            methods.setValue('serviceFee', currentRest.serviceFee.toString() ?? undefined)
            setMode(currentRest.checkbox)
            if (currentRest.schedule) {
                if (currentRest.checkbox) {
                    setAllDays({
                        workStart: currentRest.schedule[0].from,
                        workEnd: currentRest.schedule[0].to,
                        lunchStart: currentRest.schedule[0].breakFrom,
                        lunchEnd: currentRest.schedule[0].breakTo,
                        day: 'ВСЕ',
                        isActive: true,
                    })
                }
                setEditWorkTime(draft => {
                    for (let i = 0; i < currentRest.schedule.length; i++) {
                        draft[i].workStart = currentRest.schedule[i].from
                        draft[i].workEnd = currentRest.schedule[i].to
                        draft[i].lunchStart = currentRest.schedule[i].breakFrom
                        draft[i].lunchEnd = currentRest.schedule[i].breakTo
                    }
                })
            }
        }
    }, [currentRest])

    return (
        <form onSubmit={methods.handleSubmit(onSubmit, onInvalid)}>
            <FormProvider {...methods}>
                <Tabs
                    tabs={[
                        {
                            tabOption: {
                                tabName: 'Инфо',
                            },
                            child: <ModalEditRestInfo />,
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Статистика',
                            },
                            child: (
                                <Suspense fallback={<Spinner />}>
                                    <ModalEditRestStat />
                                </Suspense>
                            ),
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Заказы',
                            },
                            child: (
                                <SuspenseWithSpinner>
                                    <ModalEditRestOrders />
                                </SuspenseWithSpinner>
                            ),
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Время работы',
                            },
                            child: <ModalEditRestWorkTime />,
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Настройки интеграции',
                            },
                            child: <ModalEditRestIntegration />,
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Тип заказа',
                            },
                            child: <ModalEditTypeOrder />,
                            footerChild: <ModalEditRestInfoFooter loading={loading} />,
                        },
                    ]}
                />
            </FormProvider>
        </form>
    )
}
