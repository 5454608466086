import { AppSelect, SelectOption } from 'src/components'
import { ESelectVariant } from 'src/components/AppSelect'
import { useSetAtom } from 'jotai'
import { FC, useEffect, useState } from 'react'
import { PropsValue } from 'react-select'

import { orderPaginationAtom, updateOrdersFiltersAtom } from '../../atoms/allOrders/ordersAtom'
import { DateFilterAuto } from '../../components/DateFilterAuto'
import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import { OrderTypes } from '../../services'
import { DateFilterSelect } from '../../components/DateFilterSelect'
import { useAtom } from 'jotai/index'

type DateType = {
    dateFrom: string
    dateTo: string
}

export const orderTypes = [
    { value: 'Все заказы', label: 'Все заказы' },
    { value: 'Активные', label: 'Активные' },
    { value: 'Не принятые', label: 'Не принятые' },
    { value: 'Отмененные', label: 'Отмененные' },
    { value: 'Завершенные', label: 'Завершенные' },
]

export const AllOrdersFilters: FC<{ setSelectedType: (state: any) => void; selectedType: SelectOption }> = ({
    setSelectedType,
    selectedType,
}) => {
    const updateFilters = useSetAtom(updateOrdersFiltersAtom)
    const [dates, setDates] = useState<DateType | object>({})
    const [pagination, setPagination] = useAtom(orderPaginationAtom)

    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDates(prev => ({ ...prev, [fieldName]: val }))
    }

    useEffect(() => {
        updateFilters({
            orderType: selectedType.value,
            //@ts-ignore
            page: pagination.current,
            limit: pagination.pageSize,
        })
    }, [pagination])

    const handleSelect = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedType(value)
        updateFilters({ orderType: (value?.value as unknown as OrderTypes) || '' })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            updateFilters(dates)
        }, 300)

        return () => clearTimeout(timer)
    }, [dates])

    return (
        <DateFilterSelect
            dateFrom={(dates as DateType)?.dateFrom || ''}
            dateTo={(dates as DateType)?.dateTo || ''}
            setDateFrom={setDate('dateFrom')}
            setDateTo={setDate('dateTo')}
            selectProps={{ options: datePeriodOptions }}
            rightElement={
                <AppSelect
                    value={selectedType}
                    placeholder={'Все заказы'}
                    options={orderTypes}
                    isClearable
                    selectVariant={ESelectVariant.QUARTERY}
                    className='ml-4'
                    onChange={handleSelect}
                />
            }
        />
    )
}
