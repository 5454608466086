import { useSetAtom } from 'jotai'
import { Icon } from 'src/assets/icons'
import { getOrdersAtom } from 'src/atoms/allOrders/ordersAtom'
import { setCloseSubModalAtom, setModalCloseAtom } from 'src/atoms/global/modalAtom'
import { Button, EButtonVariant, MediumText, RegularText, SemiBoldItalicText } from 'src/components'
import moment from 'moment'

import { getCourierStatusNames, paymentNames, statusNames } from '../../utils/statusNames'
import { EOrderStatus, Order } from '../../services'
import { openDeleteOrderDialogAtom } from '../../atoms/allOrders/deleteOrderAtom'
import { openCancelDialogAtom } from '../../atoms/allOrders/cancelOrderAtom'
import getWordForm, { WORDS_LIST } from '../../utils/getWordForm'
import { notifyRestaurantAtom } from '../../atoms/allOrders/notifyRestaurantAtom'
import { FC, useState } from 'react'
import { api } from '../../axios'
import { useAtom } from 'jotai/index'

type InfoItemProps = {
    title?: string
    value?: string | number | null
}

const InfoItem = (props: InfoItemProps): JSX.Element => (
    <div className='flex gap-2'>
        <MediumText>{props.title}</MediumText>
        <RegularText>{props.value}</RegularText>
    </div>
)

const getModifiers = (dish: any) => {
    if (Array.isArray(dish?.modificatorIds)) {
        return dish?.modificatorIds?.map(item => (
            <div style={{ marginLeft: '15px', fontSize: 12, color: '#7a7a7a' }}>
                {item.title} {item.amount} - {item.price || 0} руб
            </div>
        ))
    }
    if (Array.isArray(dish?.modifiers)) {
        return dish?.modifiers?.map(item => (
            <div style={{ marginLeft: '15px', fontSize: 12, color: '#7a7a7a' }}>
                {item.title} {item.amount} - {item.price || 0} руб
            </div>
        ))
    }
    return null
}

export const InfoOrderModal: FC<{ isSubModal?: boolean; currentOrder: Order | null }> = ({ isSubModal, currentOrder }): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [orders, getOrders] = useAtom(getOrdersAtom)
    const closeModal = useSetAtom(setModalCloseAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)
    const openDeleteDialog = useSetAtom(openDeleteOrderDialogAtom)
    const openCancelDialog = useSetAtom(openCancelDialogAtom)
    const notifyRestaurant = useSetAtom(notifyRestaurantAtom)

    const closeOrder = async () => {
        try {
            setLoading(true)
            const { status } = await api({
                url: '/admin/order-complete',
                method: 'POST',
                data: { order_id: currentOrder?.order_id },
            })
            closeModal()
            getOrders()
            closeSubModal()
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onBackClick = () => {
        if (isSubModal) {
            closeSubModal()
        } else {
            closeModal()
        }
    }

    return (
        <div className='flex flex-col '>
            <div className='grid grid-cols-2 p-6 gap-10'>
                <div className='flex gap-2 flex-col' style={{ maxHeight: '60vh', overflow: 'auto' }}>
                    <SemiBoldItalicText>~Инфо о заказе~</SemiBoldItalicText>
                    <InfoItem title='Заказ:' value={currentOrder?.orderNumber} />
                    <InfoItem title='Айко:' value={currentOrder?.iikoOrderNumber} />
                    <InfoItem
                        title='Время поступления заказа:'
                        value={
                            currentOrder?.createdAt
                                ? moment(currentOrder.createdAt).utc().add('3', 'hour').format('HH:mm | DD.MM.YYYY')
                                : '-'
                        }
                    />
                    <InfoItem
                        title='Время взятие заказа:'
                        value={
                            currentOrder?.orderTookDate
                                ? moment(currentOrder.orderTookDate).utc().add('3', 'hour').format('hh:mm | DD.MM.YYYY')
                                : '-'
                        }
                    />
                    <InfoItem title='Сумма заказа:' value={currentOrder?.price} />
                    <ul>
                        <li className=''>
                            <span>&#x2022;</span>
                            <RegularText>
                                {' '}
                                {currentOrder?.isDeliveryServices
                                    ? currentOrder.order_data
                                    : `${currentOrder?.dishIdArray?.length || '0'} ${getWordForm(
                                          currentOrder?.dishIdArray?.length || 0,
                                          WORDS_LIST.position,
                                      )}:`}
                            </RegularText>
                            <ul>
                                {currentOrder?.dishIdArray?.length &&
                                    currentOrder.dishIdArray.map((dish, index) => (
                                        <li
                                            key={dish.dishId}
                                            className='pl-8 mt-1'
                                            style={index === 0 ? {} : { borderTop: '1px solid black' }}>
                                            <div>
                                                {dish.title} - {dish.price || dish.dishPrice || 0} руб
                                            </div>
                                            {getModifiers(dish)}
                                            <div>Кол-во: {dish.amount}</div>
                                        </li>
                                    ))}
                            </ul>
                        </li>
                    </ul>
                    <InfoItem title='Тип оплаты:' value={paymentNames[currentOrder?.paymentType || ''] || '-'} />
                    <InfoItem
                        title='Заведение:'
                        value={
                            currentOrder?.isDeliveryServices
                                ? 'Независимый заказ'
                                : currentOrder?.restName || currentOrder?.rest_name || '-'
                        }
                    />
                    {/*<InfoItem*/}
                    {/*    title='Примерное время доставки:'*/}
                    {/*    value={currentOrder?.deliveryTime ? currentOrder?.deliveryTime + ' мин' : '-'}*/}
                    {/*/>*/}
                    {currentOrder?.orderSubType === 'table' ? (
                        <InfoItem title='Номер стола:' value={currentOrder?.orderSubType === 'table' ? currentOrder?.tableNumber : null} />
                    ) : null}
                    {/*<InfoItem title="Время доставки:" value={currentOrder?.deliveryTime || '-'}/>*/}
                    {currentOrder?.status === EOrderStatus.CANCELLED && (
                        <InfoItem title='Причина отмены:' value={currentOrder?.cancel_reason || '-'} />
                    )}
                    {/*<SemiBoldItalicText>~Блюдо~</SemiBoldItalicText>*/}
                    {/*{currentOrder?.dishIdArray?.map(item => (*/}
                    {/*    <Fragment key={item.dishId}>*/}
                    {/*        <InfoItem title='Название:' value={item.title} />*/}
                    {/*        <InfoItem title='Название:' value={item.amount} />*/}
                    {/*    </Fragment>*/}
                    {/*))}*/}
                </div>
                <div className='flex flex-col justify-between'>
                    <div className='flex flex-col'>
                        <SemiBoldItalicText>~Клиент~</SemiBoldItalicText>
                        <InfoItem title='Имя:' value={currentOrder?.clientName || currentOrder?.client_name || '-'} />
                        {/*<InfoItem title="Адрес:" value="г.Гонгконг, ул.Навального 12\3, 12кв"/>*/}
                        <InfoItem title='Комментарий:' value={currentOrder?.comment || '-'} />
                        <InfoItem title='Телефон:' value={currentOrder?.phoneNumber || '-'} />
                    </div>

                    <div className='pt-5 flex flex-col gap-2'>
                        <SemiBoldItalicText>~Курьер~</SemiBoldItalicText>
                        <InfoItem title='ФИО:' value={currentOrder?.courierName || '-'} />
                        <InfoItem title='Таб.номер:' value={currentOrder?.courierTabNumber || '-'} />
                        <InfoItem title='Телефон:' value={currentOrder?.courierPhoneNumber || '-'} />
                    </div>
                    <div className='flex flex-wrap gap-2 justify-end mt-4'>
                        <Button onClick={closeOrder} loader={loading} variant={EButtonVariant.GREEN}>
                            <RegularText>Завершить заказ</RegularText>
                        </Button>

                        <Button variant={EButtonVariant.RED} onClick={() => notifyRestaurant(currentOrder!)}>
                            <RegularText>Уведомление ресторану</RegularText>
                        </Button>
                        {/*{currentOrder?.courier_id && (
              <Button icon="locationMarker" onClick={openOnMapClick}>
                <RegularText>
                  Открыть на карте
                </RegularText>
              </Button>
            )}*/}
                    </div>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row p-4 justify-between items-start sm:items-center gap-4'>
                <div className='flex flex-wrap gap-2'>
                    <div className='flex items-center gap-2'>
                        <Icon type='userStar' />
                        <div className='bg-red-500 rounded-lg px-3 py-2'>
                            <RegularText className='text-nowrap whitespace-nowrap'>
                                {statusNames[currentOrder?.status || ''] || currentOrder?.status || '-'}
                            </RegularText>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <Icon type='courier' />
                        <div className='bg-red-500 rounded-lg px-3 py-2'>
                            <RegularText className='text-nowrap whitespace-nowrap'>
                                {currentOrder ? getCourierStatusNames(currentOrder) : null}
                            </RegularText>
                        </div>
                    </div>
                </div>
                {/*{currentOrder.}*/}
                <div className='flex flex-wrap gap-2'>
                    <Button className='w-36' icon='cross' onClick={() => openCancelDialog()}>
                        <RegularText>Отменить</RegularText>
                    </Button>
                    {/*<Button className='w-36' variant={EButtonVariant.RED} icon='minus' onClick={openDeleteDialog}>*/}
                    {/*    <RegularText>Удалить</RegularText>*/}
                    {/*</Button>*/}
                    <Button className='w-36' onClick={onBackClick}>
                        <RegularText>Выйти</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}
