import React from 'react'
import { MediumText, RegularText, SemiBoldText } from 'src/components'
import { ShadowContainer } from 'src/components/ShadowContainer'
import { useAtomValue } from 'jotai'

import { mainStatisticsAtom } from '../../atoms/mainStatistics/getMainStatisticsAtom'

export const MainOrdersInfo = () => {
    const mainStatistics = useAtomValue(mainStatisticsAtom)

    return (
        <ShadowContainer className='flex justify-between gap-5'>
            <div>
                <div className='flex flex-col gap-2 w-60'>
                    <SemiBoldText className='text-xl'>Всего заказов {mainStatistics?.totalOrderCount ?? 0}</SemiBoldText>
                    <div className='flex justify-between'>
                        <RegularText>Выполнено:</RegularText>
                        <RegularText>{mainStatistics?.orderCount ?? 0}</RegularText>
                    </div>
                    <div className='flex justify-between'>
                        <RegularText>Не выполнено:</RegularText>
                        <RegularText>{mainStatistics?.cancelledOrderCount ?? 0}</RegularText>
                    </div>
                    <div className='flex justify-between'>
                        <RegularText>Просрочено:</RegularText>
                        <RegularText>{mainStatistics?.overdueOrders ?? 0}</RegularText>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 w-60'>
                <SemiBoldText className='text-xl'>Выдать в течении часа: {mainStatistics?.toGiveInHour?.[0]?.totalCount ?? 0}</SemiBoldText>
                <div className='flex justify-between'>
                    <RegularText>Собрано:</RegularText>
                    <RegularText>{mainStatistics?.toGiveInHour?.[0]?.assembled ?? 0}</RegularText>
                </div>
                <div className='flex justify-between'>
                    <RegularText>Не собрано:</RegularText>
                    <RegularText>{mainStatistics?.toGiveInHour?.[0]?.not_assembled ?? 0}</RegularText>
                </div>
            </div>
            <div className='flex flex-col gap-2 w-60'>
                <SemiBoldText className='text-xl'>Среднее время готовки:</SemiBoldText>
                <div className='flex items-center'>
                    <div className='flex gap-1 items-end'>
                        <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.averageCookTime ?? 0}</MediumText>
                        <MediumText className='text-blue-900 text-xl'>мин</MediumText>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 w-60'>
                <SemiBoldText className='text-xl'>Среднее время готовки locali:</SemiBoldText>
                <div className='flex items-center'>
                    <div className='flex gap-1 items-end'>
                        <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.averageLokalyCookTime || 0}</MediumText>
                        <MediumText className='text-blue-900 text-xl'>мин</MediumText>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 w-60'>
                <SemiBoldText className='text-xl'>Среднее время доставки:</SemiBoldText>
                <div className='flex items-center'>
                    <div className='flex gap-1 items-end'>
                        <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.averageDeliveryTime ?? 0}</MediumText>
                        <MediumText className='text-blue-900 text-xl'>мин</MediumText>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 w-60'>
                <SemiBoldText className='text-xl'>Среднее время доставки курьером:</SemiBoldText>
                <div className='flex items-center'>
                    <div className='flex gap-1 items-end'>
                        <MediumText className='text-blue-900 text-4xl'>{mainStatistics?.averageDeliveryTimeLokaly ?? 0}</MediumText>
                        <MediumText className='text-blue-900 text-xl'>мин</MediumText>
                    </div>
                </div>
            </div>
        </ShadowContainer>
    )
}
