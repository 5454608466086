import { useAtomValue, useSetAtom } from 'jotai'
import { Controller, useFormContext } from 'react-hook-form'
import { setEditRestDeliveryZonesAtom } from 'src/atoms/restaraunts/restModalsAtom'
import {
    AppSelect,
    Button,
    Checkbox,
    EButtonVariant,
    InputWrapper,
    NamedSelect,
    RegularText,
    SemiBoldItalicText,
    TextArea,
    TextInput,
} from 'src/components'
import { getRestGroupAtom } from 'src/atoms/restaraunts/createRestGroupAtom'
import { useMemo } from 'react'
import { openDeleteRestDialogAtom } from 'src/atoms/restaraunts/deleteRestAtom'

import { blockRestAtom } from '../../atoms/restaraunts/blockRestAtom'
import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import { AppCitySelect, ESelectVariant } from '../../components/AppCitySelect'
import { NamedCitySelect } from '../../components/NamedCitySelect'

import { EditRestFormData } from './EditRestSchema'

const kitchenTypes = [
    { label: 'Европейская', value: 'Европейская' },
    { label: 'Азиатская', value: 'Азиатская' },
    { label: 'Средиземн.', value: 'Средиземн.' },
    { label: 'Японская', value: 'Японская' },
    { label: 'Кавказская', value: 'Кавказская' },
    { label: 'Русская', value: 'Русская' },
    { label: 'Латиноамериканская', value: 'Латиноамериканская' },
    { label: 'Кофе', value: 'Кофе' },
    { label: 'Фастфуд', value: 'Фастфуд' },
]

const restTypes = [
    { label: 'Ресторан', value: 'Ресторан' },
    { label: 'Бар', value: 'Бар' },
    { label: 'Кафе', value: 'Кафе' },
    { label: 'Столовая', value: 'Столовая' },
    { label: 'Буфет', value: 'Буфет' },
    { label: 'Кафетерий', value: 'Кафетерий' },
    { label: 'Кофейня', value: 'Кофейня' },
]

export const ModalEditRestInfo = (): JSX.Element => {
    const openDeliveryZones = useSetAtom(setEditRestDeliveryZonesAtom)
    const restGroups = useAtomValue(getRestGroupAtom)
    const onClickDeleteRest = useSetAtom(openDeleteRestDialogAtom)
    const blockRest = useSetAtom(blockRestAtom)
    const currentRest = useAtomValue(currentRestAtom)

    const options = useMemo(() => {
        if (restGroups.data) {
            return restGroups.data.map(el => ({
                label: el.title,
                value: el.restgroup_id,
            }))
        }
        return []
    }, [restGroups])

    const {
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext<EditRestFormData>()

    const handlePaymentType = ev => {
        const { name } = ev.target
        let currentVal = getValues('paymentMethods') || []
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('paymentMethods', currentVal)
    }

    const handleDelivPickup = ev => {
        const { name } = ev.target
        let currentVal = getValues('orderMethods') || []
        if (currentVal.includes(name)) {
            currentVal = currentVal.filter(item => item !== name)
        } else {
            currentVal.push(name)
        }

        setValue('orderMethods', currentVal)
    }

    return (
        <div className='flex flex-col justify-between h-full'>
            <div className='flex py-5 justify-evenly'>
                <div className='flex flex-col gap-2 items-end'>
                    <SemiBoldItalicText className='text-base self-center text-blue-700'>~Основные данные 1~</SemiBoldItalicText>
                    <Controller
                        name='group_id'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <NamedSelect
                                name='Группа'
                                selectProps={{
                                    placeholder: 'Группа',
                                    options: [{ value: null, label: 'Не выбран' }, ...options],
                                    selectVariant: ESelectVariant.SECONDARY,
                                    value: options.find(el => el.value === value),
                                    onChange: val => onChange(val?.value),
                                    error: errors.group_id?.message,
                                }}
                            />
                        )}
                    />
                    <InputWrapper name='Название'>
                        <Controller
                            name='restName'
                            control={control}
                            render={props => <TextInput {...props.field} placeholder='Название ресторана' error={errors.restName} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Тип кухни'>
                        <Controller
                            control={control}
                            name='kitchenType'
                            render={props => (
                                <AppSelect
                                    placeholder='Типы кухни'
                                    selectVariant={ESelectVariant.SECONDARY}
                                    options={kitchenTypes}
                                    value={kitchenTypes.find(el => el.value === props.field.value)}
                                    onChange={val => props.field.onChange(val?.value)}
                                    error={errors.kitchenType?.message}
                                />
                            )}
                        />
                    </InputWrapper>
                    <InputWrapper name='Тип заведения'>
                        <Controller
                            control={control}
                            name='restType'
                            render={props => (
                                <AppSelect
                                    placeholder='Типы заведения'
                                    selectVariant={ESelectVariant.SECONDARY}
                                    options={restTypes}
                                    value={restTypes.find(el => el.value === props.field.value)}
                                    onChange={val => props.field.onChange(val?.value)}
                                    error={errors.restType?.message}
                                />
                            )}
                        />
                    </InputWrapper>
                    <InputWrapper name='Управляющий'>
                        <Controller
                            control={control}
                            name='manager'
                            render={props => <TextInput placeholder='Управляющий' {...props.field} error={errors.manager} />}
                        />
                    </InputWrapper>
                    {/*<InputWrapper name='Комиссия с продаж'>*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name='restPercent'*/}
                    {/*        render={props => (*/}
                    {/*            <TextInput placeholder='Процент комиссии' type={'number'} {...props.field} error={errors.manager} />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</InputWrapper>*/}
                    {/*<InputWrapper name='Сервисный сбор'>*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name='serviceFee'*/}
                    {/*        render={props => (*/}
                    {/*            <TextInput placeholder='Сервисный сбор' type={'number'} {...props.field} error={errors.manager} />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</InputWrapper>*/}
                    <div className='flex gap-7'>
                        <InputWrapper name='Город'>
                            <Controller
                                control={control}
                                name='city'
                                render={props => (
                                    <NamedCitySelect
                                        name='Адрес'
                                        address
                                        selectProps={{
                                            ...props.field,
                                            selectVariant: ESelectVariant.PRIMARY,
                                            value: getValues('city')
                                                ? {
                                                      value: getValues('city')!,
                                                      label: getValues('city'),
                                                  }
                                                : undefined,
                                            onChange: val => {
                                                setValue('city', val?.label || '')
                                                setValue('longitude', val?.value ? Number(val.value.split(',')[0]) : 0)
                                                setValue('latitude', val?.value ? Number(val.value.split(',')[1]) : 0)
                                            },
                                        }}
                                    />
                                )}
                            />
                        </InputWrapper>
                    </div>
                    {/*<div className="flex gap-2">
            <InputWrapper name="Улица">
              <Controller
                control={control}
                name="street"
                render={props => (
                  <TextInput placeholder="Улица" {...props.field} error={errors.street} className="w-inputM"/>
                )}
              />
            </InputWrapper>
            <InputWrapper name="Корпус">
              <Controller
                control={control}
                name="corpus"
                render={props => (
                  <TextInput placeholder="Корпус" {...props.field} error={errors.corpus} className="w-inputM"/>
                )}
              />
            </InputWrapper>
          </div>*/}
                    <div className='flex'>
                        <Button type='button' icon='courier' onClick={openDeliveryZones}>
                            <RegularText>Зоны доставки</RegularText>
                        </Button>
                    </div>

                    <div className='flex gap-4'>
                        {/*<div className='flex gap-1'>*/}
                        {/*    <RegularText>Доставка</RegularText>*/}
                        {/*    <Controller*/}
                        {/*        control={control}*/}
                        {/*        render={({ field }) => (*/}
                        {/*            <Checkbox name='deliv' onChange={handleDelivPickup} checked={(field.value || []).includes('deliv')} />*/}
                        {/*        )}*/}
                        {/*        name='orderMethods'*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<div className='flex gap-1'>*/}
                        {/*    <RegularText>Cамовывоз</RegularText>*/}
                        {/*    <Controller*/}
                        {/*        control={control}*/}
                        {/*        render={({ field }) => (*/}
                        {/*            <Checkbox name='pickup' onChange={handleDelivPickup} checked={(field.value || []).includes('pickup')} />*/}
                        {/*        )}*/}
                        {/*        name='orderMethods'*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <SemiBoldItalicText className='text-base self-center text-blue-700'>~Основные данные 2~</SemiBoldItalicText>
                    <InputWrapper name='Телефон'>
                        <Controller
                            control={control}
                            name='phoneNumber'
                            render={props => <TextInput placeholder='Телефон' {...props.field} error={errors.phoneNumber} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Whatsapp'>
                        <Controller
                            control={control}
                            name='whatsApp'
                            render={props => <TextInput placeholder='Whatsapp' {...props.field} error={errors.whatsApp} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Telegram'>
                        <Controller
                            control={control}
                            name='telegram'
                            render={props => <TextInput placeholder='Telegram' {...props.field} error={errors.telegram} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Email'>
                        <Controller control={control} name='email' render={props => <TextInput placeholder='Email' {...props.field} />} />
                    </InputWrapper>
                    <InputWrapper name='Сайт'>
                        <Controller
                            control={control}
                            name='website'
                            render={props => <TextInput placeholder='Сайт' {...props.field} error={errors.website} />}
                        />
                    </InputWrapper>
                    {/*<InputWrapper name='Тип оплаты'>*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name='paymentMethods'*/}
                    {/*        render={props => (*/}
                    {/*            <div className='flex items-center' style={{ width: '300px' }}>*/}
                    {/*                <Checkbox*/}
                    {/*                    name={'cash'}*/}
                    {/*                    onChange={handlePaymentType}*/}
                    {/*                    checked={(props.field.value || []).includes('cash')}*/}
                    {/*                />*/}
                    {/*                <RegularText className={'ml-2 mr-8'}>Наличные</RegularText>*/}
                    {/*                <Checkbox*/}
                    {/*                    name={'online'}*/}
                    {/*                    onChange={handlePaymentType}*/}
                    {/*                    checked={(props.field.value || []).includes('online')}*/}
                    {/*                />*/}
                    {/*                <RegularText className={'ml-2'}>Картой</RegularText>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</InputWrapper>*/}
                    {/*<InputWrapper name='Типы курьеров'>*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name='isOurCouriers'*/}
                    {/*        render={props => (*/}
                    {/*            <AppSelect*/}
                    {/*                placeholder='Типы курьеров'*/}
                    {/*                selectVariant={ESelectVariant.SECONDARY}*/}
                    {/*                options={[*/}
                    {/*                    { label: 'Lokali', value: 'Lokali' },*/}
                    {/*                    { label: 'Свои', value: 'Свои' },*/}
                    {/*                ]}*/}
                    {/*                value={[*/}
                    {/*                    { label: 'Lokali', value: 'Lokali' },*/}
                    {/*                    { label: 'Свои', value: 'Свои' },*/}
                    {/*                ].find(el => el.value === props.field.value)}*/}
                    {/*                onChange={val => props.field.onChange(val?.value)}*/}
                    {/*                error={errors.isOurCouriers?.message}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</InputWrapper>*/}
                    <Controller
                        control={control}
                        name='comment'
                        render={props => <TextArea className='self-end' {...props.field} placeholder='Комментарий' />}
                    />
                    <InputWrapper name='Логин'>
                        <Controller
                            name='login'
                            control={control}
                            render={props => <TextInput placeholder='Логин' {...props.field} error={errors.login} />}
                        />
                    </InputWrapper>
                    <InputWrapper name='Пароль'>
                        <Controller
                            name='password'
                            control={control}
                            render={props => <TextInput placeholder='Пароль' {...props.field} error={errors.password} />}
                        />
                    </InputWrapper>
                </div>
            </div>
            <div className='flex justify-between p-5'>
                <div className='flex gap-5'>
                    <Button
                        variant={EButtonVariant.QUARTERY}
                        onClick={() => {
                            if (currentRest?.is_blocked) {
                                blockRest('unblock')
                            } else {
                                blockRest('block')
                            }
                        }}>
                        <RegularText className='underline text-primaryColor'>
                            {currentRest?.is_blocked ? 'Разблокировать' : 'Заблокировать'}
                        </RegularText>
                    </Button>
                    <Button variant={EButtonVariant.QUARTERY} onClick={onClickDeleteRest}>
                        <RegularText className='underline text-primaryColor'>Удалить безвозвратно</RegularText>
                    </Button>
                </div>
            </div>
        </div>
    )
}
