import { AppSelect, Button, MediumText, RegularText, SelectOption, SuspenseWithSpinner, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { ESelectVariant } from 'src/components/AppSelect'
import { useAtomValue, useSetAtom } from 'jotai'
import { openAssignDialogAtom } from 'src/atoms/allOrders/assignOrderAtom'
import { openDeleteOrderDialogAtom } from 'src/atoms/allOrders/deleteOrderAtom'
import { currentOrderAtom, getOrdersAtom, ordersListAtom, updateOrdersFiltersAtom } from 'src/atoms/allOrders/ordersAtom'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { PropsValue, SingleValue } from 'react-select'
import moment from 'moment/moment'

import { AppCitySelect } from '../../components/AppCitySelect'
import { getRestAtom, restsAtom } from '../../atoms/restaraunts/restAtom'
import { currentRestGroupAtom } from '../../atoms/restaraunts/restGroupAtom'
import { setCreateOrderModalAtom } from '../../atoms/allOrders/modalsOfOrderAtom'
import { CreateOrderRequest } from '../../services'
import { createOrderAtom } from '../../atoms/allOrders/createOrderAtom'
import { currentCourierAtom } from '../../atoms/allOrders/couriersListAtom'
import { Excel } from '../../services/Excel'
import { getCourierStatusNames, getOrderStatusNames, paymentNames, statusNames } from '../../utils/statusNames'

import { AllOrderTable } from './AllOrderTable'
import styles from './styles.module.scss'

function canBeSaved(body: CreateOrderRequest): boolean {
    return Boolean(
        body['from_street'] &&
            body['to_street'] &&
            body['clientId'] &&
            body['distance'] &&
            body['price'] &&
            body['order_data'] &&
            body['paymentType'] &&
            body['deliveryType'],
    )
}

const THEADS = [
    'Номер заказа',
    'Статус ресторана',
    'Статус курьера',
    'Статус заказа',
    'Дата/Время поступления заказа',
    'Сумма заказа',
    'Тип оплаты',
    'Заведение',
    'Имя клиента',
    'Контактный номер телефона клиента',
    'Причина отмены',
]
export const AllOrderTableBody: FC<{ selectedType: SelectOption }> = ({ selectedType }) => {
    const createOrder = useSetAtom(createOrderAtom)
    const assignOrder = useSetAtom(openAssignDialogAtom)
    const createOrderModal = useSetAtom(setCreateOrderModalAtom)
    const updateFilters = useSetAtom(updateOrdersFiltersAtom)
    const setCurrentRestGroup = useSetAtom(currentRestGroupAtom)
    const openDeleteDialog = useSetAtom(openDeleteOrderDialogAtom)
    const currentOrder = useAtomValue(currentOrderAtom)
    const currentCourier = useAtomValue(currentCourierAtom)
    const [ordersSearch, setOrdersSearch] = useState('')
    const [selectedCity, setSelectedCity] = useState<SelectOption>()
    const [selectedRest, setSelectedRest] = useState<SelectOption>()
    const restaurants = useAtomValue(restsAtom)
    const data = useAtomValue(ordersListAtom)
    const [typeOrder, setTypeOrder] = useState<PropsValue<SelectOption>>()

    const onRefreshClick = () => {
        setOrdersSearch('')
    }

    const handleSelectCity = (val?: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedCity(value)
        updateFilters({ city: value?.value ? value.value.split(',')[0] : '' })
    }

    const handleTypeOrderChange = (val: SingleValue<SelectOption>) => {
        setTypeOrder(val)
        updateFilters({ subType: val?.value })
    }

    const handleSelectRest = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption

        setSelectedRest(value)

        if (typeof value?.value === 'boolean') {
            return updateFilters({ restId: '', independent: true })
        }
        updateFilters({ restId: value?.value || '', independent: undefined })
    }

    const onCreate = (body: CreateOrderRequest) => {
        if (!canBeSaved(body)) return

        createOrder(body).then()
    }

    const onCreateClick = () => {
        createOrderModal({ pageProps: { onSave: onCreate } })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            updateFilters({ q: ordersSearch })
        }, 300)

        return () => clearTimeout(timer)
    }, [ordersSearch])

    useEffect(() => {
        setCurrentRestGroup(null)
    }, [])

    const onDownloadClick = () => {
        if (!data) return
        const KEYS = [
            'orderNumber',
            'status',
            'courierStatus',
            'status',
            'createdAt',
            'price',
            'paymentType',
            'restName',
            'clientName',
            'clientPhoneNumber',
            'cancel_reason',
        ]

        const FN = (key: string, node: any) => {
            switch (key) {
                case 'courierStatus':
                    return getCourierStatusNames(node)
                case 'status':
                    return getOrderStatusNames(node)
                case 'paymentType':
                    return paymentNames[node.paymentType] || node.paymentType
                case 'createdAt':
                    return moment(node[key]).format('DD.MM.YYYY')
                case 'restName':
                    return node.isDeliveryServices ? 'Независимый заказ' : node.restName || node.rest_name || '-'
                case 'clientName':
                    return node.clientName || node.client_name || '-'
                case 'clientPhoneNumber':
                    return node.clientPhoneNumber || node.phoneNumber || '-'
                case 'cancel_reason':
                    return node.cancel_reason || '-'
            }
            return ''
        }

        Excel.generateFile({
            heads: THEADS,
            keys: KEYS,
            data: data,
            fn: FN,
            fileName: 'orders',
        })
    }

    return (
        <div className='p-5'>
            <div className={classNames('flex gap-3', styles.filterRow)}>
                <TextInput
                    className='w-inputL'
                    rightIcon='search'
                    placeholder='Поиск по номеру заказа'
                    value={ordersSearch}
                    onChange={ev => setOrdersSearch(ev.target.value)}
                />
                <RoundedWrapper icon='refresh' onClick={onRefreshClick} />
                <RoundedWrapper icon='move' disabled={!currentOrder || !currentCourier} onClick={() => assignOrder()} />
                <RoundedWrapper icon='trash' onClick={() => openDeleteDialog()} disabled={!currentOrder} />
                <Button icon='plus' variant={EButtonVariant.GREEN} className='mr-1.5' onClick={onCreateClick}>
                    <RegularText>Сформировать</RegularText>
                </Button>
                <AppCitySelect
                    selectVariant={ESelectVariant.QUARTERY}
                    placeholder={'Все города'}
                    isClearable
                    onChange={handleSelectCity}
                    value={selectedCity}
                />
                <AppSelect
                    value={selectedRest}
                    onChange={handleSelectRest}
                    options={
                        restaurants?.length
                            ? [
                                  { value: true, label: 'Независимые заказы' },
                                  ...restaurants.map(item => ({ value: item.rest_id, label: item.restName })),
                              ]
                            : []
                    }
                    selectVariant={ESelectVariant.QUARTERY}
                    placeholder={'Все рестораны'}
                    isClearable
                />
                <Button variant={EButtonVariant.GREEN} icon='excel' onClick={onDownloadClick}>
                    <MediumText>Download</MediumText>
                </Button>
            </div>

            <div style={{ width: '200px', marginTop: 20 }}>
                <AppSelect
                    isClearable
                    value={typeOrder}
                    options={[
                        { label: 'Доставка', value: 'delivery' },
                        { label: 'Самовывоз', value: 'pickup' },
                        { label: 'Забрать на кассе', value: 'terminal' },
                        { label: 'Принести к столу', value: 'table' },
                    ]}
                    onChange={handleTypeOrderChange}
                    placeholder='Тип заказа'
                    selectVariant={ESelectVariant.SECONDARY}
                />
            </div>
            <div className='mt-4'>
                <SuspenseWithSpinner>
                    <AllOrderTable selectedType={selectedType} />
                </SuspenseWithSpinner>
            </div>
        </div>
    )
}
