import {
    AppImage,
    AppSelect,
    Button,
    Checkbox,
    EButtonVariant,
    MediumText,
    RegularText,
    SemiBoldItalicText,
    SemiBoldText,
    TextArea,
    TextInput,
} from 'src/components'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import { useAtomValue } from 'jotai/index'
import React, { useCallback, useState } from 'react'
import { PropsValue } from 'react-select'
import { useSetAtom } from 'jotai'

import { allClientGroupsAtom } from '../../atoms/clientDb/clientGroupsAtom'
import { SelectOption } from '../../components/AppCitySelect'
import { ClientDB, LokaliApi } from '../../services'
import { FastNotificationRequest } from '../../services/LocaliRepository/types/pushNotifications'
import { closeDialogAtom, dialogStateAtom } from '../../atoms/global/dialogAtom'
import { Assets } from '../../utils'

type ClientProps = {
    name: string
    checked: boolean
    onChange: () => void
}

type SuccessDialogProps = {
    closeDialog: () => void
}

const Client = (props: ClientProps): JSX.Element => (
    <div className='flex gap-2 items-center ml-7'>
        <Checkbox onChange={props.onChange} checked={props.checked} />
        <RegularText>{props.name}</RegularText>
    </div>
)

const SuccessDialog = (props: SuccessDialogProps): JSX.Element => (
    <div className='flex flex-col items-center justify-center h-full p-8'>
        <div className='size-14'>
            <AppImage src={Assets.happySmiley} />
        </div>
        <div className='flex-1 flex flex-col justify-center items-center'>
            <RegularText>Быстрое уведомление было успешно отправлено</RegularText>
        </div>
        <div className='flex gap-2'>
            <Button className='!w-44 flex justify-center !py-5' variant={EButtonVariant.PRIMARY} onClick={props.closeDialog}>
                <RegularText className='!text-base'>Ок</RegularText>
            </Button>
        </div>
    </div>
)

export const ModalFastNotification = (): JSX.Element => {
    const setDialogState = useSetAtom(dialogStateAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const { data: clientGroups } = useAtomValue(allClientGroupsAtom)
    const [selectedGroup, setSelectedGroup] = useState<SelectOption>()
    const [selectedGroupClients, setSelectedGroupClients] = useState<ClientDB[]>([])
    const [selectedClients, setSelectedClients] = useState<string[]>([])
    const [heading, setHeading] = useState<string>()
    const [text, setText] = useState<string>()

    const handleGroup = useCallback(
        async (val: PropsValue<SelectOption>) => {
            const group = val as SelectOption
            if (group.value === selectedGroup?.value) return

            setSelectedGroup(group)
            setSelectedClients([])
            getGroupClients(group.value).then()
        },
        [selectedGroup],
    )

    const getGroupClients = useCallback(async (groupId: string) => {
        try {
            const clients = await LokaliApi.getClientsDB({ group_id: groupId, limit: 0 })

            setSelectedGroupClients(clients)
        } catch (e) {
            console.warn(e)
        }
    }, [])

    const onSend = async () => {
        try {
            if (!selectedGroup || !heading || !text) return
            const body: FastNotificationRequest = {
                group_id: selectedGroup?.value,
                client_ids: selectedGroup?.value === 'all-clients' ? 'all-clients' : selectedClients,
                heading,
                text,
            }
            const data = await LokaliApi.sendFastNotification(body)

            if (data?.message === 'success') {
                setDialogState({
                    isOpen: true,
                    children: <SuccessDialog closeDialog={closeDialog} />,
                })
                onClear()
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const onClear = () => {
        setHeading('')
        setText('')
    }

    const onAllClientsClick = () => {
        const isAllSelected = selectedClients.length === selectedGroupClients?.length
        if (isAllSelected) {
            setSelectedClients([])
        } else {
            const mapped = selectedGroupClients?.map(client => client.clientData.client_id) || []
            setSelectedClients(mapped)
        }
    }

    const onClientClick = (val: string) => {
        if (selectedClients.includes(val)) {
            setSelectedClients(prev => prev.filter(item => item !== val))
        } else {
            setSelectedClients(prev => [...prev, val])
        }
    }

    return (
        <div className='flex gap-5 p-5 h-full pt-20 justify-evenly'>
            <div className='flex flex-col gap-5' style={{ width: '40%' }}>
                <div className='flex flex-col gap-2'>
                    <SemiBoldItalicText>~Группы~</SemiBoldItalicText>
                    <AppSelect
                        isSearchable
                        placeholder='Группы'
                        onChange={handleGroup}
                        value={selectedGroup}
                        options={
                            clientGroups
                                ? [
                                      {
                                          value: 'all-clients',
                                          label: 'Все клиенты',
                                      },
                                      ...clientGroups.map(item => ({
                                          value: item.clientgroup_id,
                                          label: item.title,
                                      })),
                                  ]
                                : [
                                      {
                                          value: 'all-clients',
                                          label: 'Все клиенты',
                                      },
                                  ]
                        }
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <SemiBoldItalicText>~Клиенты~</SemiBoldItalicText>
                    {/*<div className="flex gap-2">
            <TextInput rightIcon="search" placeholder="Поиск"/>
            <RoundedWrapper icon="refresh"/>
          </div>*/}
                </div>
                <div className='flex flex-col'>
                    {/*<div className={classNames(
            'flex gap-14 items-center',
            ' bg-darkBlue-600 h-10',
            'border border-solid border-mainBackgroundColor')}>
            <div className="flex items-center gap-1">
              <MediumText className="text-darkBlue-900">
                ФИО
              </MediumText>
              <Icon type="arrowSm" size={14}/>
            </div>
            <div className="flex items-center gap-1">
              <MediumText className="text-darkBlue-900">
                Телефон
              </MediumText>
              <Icon type="arrowSm" size={14}/>
            </div>
            <div className="flex items-center gap-1">
              <MediumText className="text-darkBlue-900">
                Общая сумма
              </MediumText>
              <Icon type="arrowSm" size={14}/>
            </div>
          </div>*/}
                    {selectedGroup?.value !== 'all-clients' && selectedGroup && !!selectedGroupClients?.length ? (
                        <div className='checkboxes'>
                            <div className='flex gap-2 items-center'>
                                <Checkbox checked={selectedClients.length === selectedGroupClients?.length} onChange={onAllClientsClick} />
                                <MediumText>Выбрать всех клиентов</MediumText>
                            </div>
                            <div className='h-96 overflow-scroll py-2'>
                                {selectedGroupClients?.map(client => (
                                    <Client
                                        key={client.clientData.client_id}
                                        onChange={() => onClientClick(client.clientData.client_id)}
                                        checked={selectedClients.includes(client.clientData.client_id)}
                                        name={`${client.clientData.firstName} 
                    ${client.clientData.middleName} ${client.clientData.lastName}`}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <RegularText>Нет клиентов</RegularText>
                    )}
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <SemiBoldItalicText>~Информация уведомления~</SemiBoldItalicText>
                <div className='flex flex-col gap-2'>
                    <SemiBoldText>Заголовок</SemiBoldText>
                    <TextInput placeholder='Заголовок' value={heading} onChange={ev => setHeading(ev.target.value)} />
                </div>
                <div className='flex flex-col gap-5'>
                    <SemiBoldText>Текст уведомления</SemiBoldText>
                    <TextArea
                        className='w-full'
                        placeholder='Текст уведомления'
                        value={text}
                        onChange={ev => {
                            const target = ev.target as HTMLTextAreaElement
                            setText(target.value)
                        }}
                    />
                    <div className='flex gap-2 self-end'>
                        <Button variant={EButtonVariant.PRIMARY} onClick={onSend} disabled={!selectedGroup || !heading || !text}>
                            <RegularText className='text-white'>Отправить</RegularText>
                        </Button>
                        <RoundedWrapper icon='refresh' onClick={onClear} />
                    </div>
                </div>
            </div>
        </div>
    )
}
