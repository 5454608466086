import { boolean, z } from 'zod'

const CreateRestSchema = z.object({
    restName: z.string({ required_error: 'Обязательное поле' }),
    address: z.string().optional(),
    cityKey: z.string().optional(),
    isOurCouriers: z.string().optional(),
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    maxPrepareTime: z.number().optional(),
    minOrderPrice: z.number().optional(),
    paymentMethods: z.string().array().optional(),
    orderMethods: z.string().array().optional(),
    phoneNumber: z.string().optional(),
    servType: boolean().optional(),
    city: z.string({ required_error: 'Обязательное поле' }),
    // house: z.string({ required_error: 'Обязательное поле' }),
    // street: z.string({ required_error: 'Обязательное поле' }),
    // corpus: z.string().optional(),
    whatsApp: z.string().optional(),
    telegram: z.string().optional(),
    website: z.string().optional(),
    group_id: z.string().optional(),
    comment: z.string().optional(),
    cashPaymentId: z.string().optional(),
    cashPaymentKind: z.string().optional(),
    onlinePaymentId: z.string().optional(),
    onlinePaymentKind: z.string().optional(),
    manager: z.string().optional(),
    schedule: z.string().optional(),
    order_region: z.array(z.array(z.number()).optional()).optional(),
    apiLogin: z.string().optional(),
    apiKey: z.string().optional(),
    organizationId: z.string().optional(),
    terminalgroupId: z.string().optional(),
    minPrepareTime: z.string().optional(),
    maxOrderPrice: z.string().optional(),
    kitchenType: z.string().optional(),
    tab_number: z.string().optional(),
    restType: z.string().optional(),
    restImageUrl: z.string().optional(),
    restPercent: z.string().optional().or(z.number().optional()),
    login: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    password: z.string({ required_error: 'Обязательное поле' }).min(1, 'Обязательное поле'),
    email: z.string().optional(),
    delivery: z
        .object({
            comission: z.number().optional(),
            serviceFee: z.number().optional(),
            paymentTypes: z.string().array().optional(),
            minimumPrice: z.number().optional(),
            isOurCouriers: z.boolean().optional(),
        })
        .optional(),
    deliveryEnabled: z.boolean().optional(),
    pickupEnabled: z.boolean().optional(),
    terminalEnabled: z.boolean().optional(),
    tableEnabled: z.boolean().optional(),
    pickup: z
        .object({
            comission: z.number().optional(),
            serviceFee: z.number().optional(),
            paymentTypes: z.string().array().optional(),
            minimumPrice: z.number().optional(),
        })
        .optional(),
    table: z
        .object({
            comission: z.number().optional(),
            serviceFee: z.number().optional(),
            paymentTypes: z.string().array().optional(),
            minimumPrice: z.number().optional(),
            tableCount: z.number().optional(),
        })
        .optional(),
    terminal: z
        .object({
            comission: z.number().optional(),
            serviceFee: z.number().optional(),
            paymentTypes: z.string().array().optional(),
            minimumPrice: z.number().optional(),
        })
        .optional(),
})

export type CreateRestFormData = z.infer<typeof CreateRestSchema>

export { CreateRestSchema }
