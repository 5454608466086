import { Controller, useFormContext } from 'react-hook-form'
import {
    AppImage,
    AppSelect,
    Button,
    Checkbox,
    EButtonVariant,
    InputWrapper,
    RegularText,
    SemiBoldItalicText,
    TextInput,
} from 'src/components'
import { Assets } from 'src/utils'

import { CreateRestFormData } from './CreateRestSchema'
import { useDebounce } from '../../hooks'
import { api } from '../../axios'
import { ChangeEvent, useEffect, useState } from 'react'
import { useAtomValue } from 'jotai/index'
import { currentRestAtom } from '../../atoms/restaraunts/restAtom'

export const ModalEditRestIntegration = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false)
    const [paymentTypes, setPaymentTypes] = useState<
        {
            name: string
            id: string
            code: string
            paymentTypeKind: string
        }[]
    >([])
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<CreateRestFormData>()
    const currentRest = useAtomValue(currentRestAtom)

    const debounceValueApiKey = useDebounce(watch('apiKey'), 500)
    const debounceValueOrganizationId = useDebounce(watch('organizationId'), 500)

    const getPaymentTypes = async (apiKey: string, organizationId: string) => {
        try {
            const { data } = await api({ url: '/admin/rest/payment-types', params: { apiKey, organizationId } })
            if (Array.isArray(data)) {
                setPaymentTypes(data)
            }
        } catch (e) {
            setValue('onlinePaymentId', '')
            setValue('onlinePaymentKind', '')
            setValue('cashPaymentKind', '')
            setValue('cashPaymentId', '')
            console.log(e)
        }
    }

    const updatePaymentType = async () => {
        setLoading(true)
        await api({
            url: '/admin/sync-menu',
            method: 'POST',
            data: {
                organizationId: watch('organizationId'),
                apiKey: watch('apiKey'),
                rest_id: currentRest?.rest_id,
            },
        })
        setLoading(false)
    }

    const changeServType = (e: ChangeEvent<HTMLInputElement>) => {
        setValue('servType', e.target.checked)
    }

    useEffect(() => {
        getPaymentTypes(debounceValueApiKey || '', debounceValueOrganizationId || '')
    }, [debounceValueApiKey, debounceValueOrganizationId])

    return (
        <div className='flex flex-col justify-center items-center pt-10 gap-10 h-full'>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div className='w-full' />
                <SemiBoldItalicText className='w-full text-base text-blue-700 text-center'>~Настройки интеграции~</SemiBoldItalicText>
                <div className={'w-full'}>
                    <Checkbox checked={watch('servType')} onChange={changeServType} />
                    <RegularText style={{ marginLeft: '30px' }}>Работает с айко</RegularText>
                </div>
            </div>
            <div className='grid grid-cols-2 content-center items-center w-full gap-2 p-2'>
                <div className='flex items-center gap-1'>
                    <RegularText>'ApiLogin</RegularText>
                    <Controller
                        control={control}
                        name='apiLogin'
                        render={props => <TextInput placeholder='ApiLogin' {...props.field} error={errors.apiLogin} />}
                    />
                </div>
                <InputWrapper className='flex items-center' name='ID Организации'>
                    <Controller
                        control={control}
                        name='organizationId'
                        render={props => <TextInput placeholder='ID Организации' {...props.field} error={errors.organizationId} />}
                    />
                </InputWrapper>
                <div className='flex gap-1 items-center'>
                    <RegularText>'API Ключ</RegularText>
                    <Controller
                        control={control}
                        name='apiKey'
                        render={props => <TextInput placeholder='API Ключ' {...props.field} error={errors.apiKey} />}
                    />
                </div>
                <div className='flex  gap-1  items-center justify-end'>
                    <RegularText>ID Группы терминала</RegularText>
                    <Controller
                        control={control}
                        name='terminalgroupId'
                        render={props => <TextInput placeholder='ID Группы терминала' {...props.field} error={errors.organizationId} />}
                    />
                </div>
                <div className='flex  gap-1 items-center'>
                    <RegularText>
                        Способ оплаты <br /> Айко (налич)
                    </RegularText>
                    <Controller
                        control={control}
                        name='cashPaymentId'
                        render={props => (
                            <AppSelect
                                value={{
                                    value: paymentTypes.find(el => el.id === props.field.value)?.id,
                                    label: paymentTypes.find(el => el.id === props.field.value)?.name,
                                }}
                                onChange={val => {
                                    props.field.onChange(val?.value)
                                    const i = paymentTypes.find(item => item.id === val?.value)
                                    setValue('cashPaymentKind', i?.paymentTypeKind || '')
                                }}
                                options={paymentTypes.map(item => ({ value: item.id, label: item.name }))}
                            />
                        )}
                    />
                </div>
                <div className='flex gap-1 items-center justify-end'>
                    <RegularText>
                        Способ оплаты <br /> Айко (безналич)
                    </RegularText>
                    <Controller
                        control={control}
                        name='onlinePaymentId'
                        render={props => (
                            <AppSelect
                                value={{
                                    value: paymentTypes.find(el => el.id === props.field.value)?.id,
                                    label: paymentTypes.find(el => el.id === props.field.value)?.name,
                                }}
                                onChange={val => {
                                    props.field.onChange(val?.value)
                                    const i = paymentTypes.find(item => item.id === val?.value)
                                    setValue('onlinePaymentKind', i?.paymentTypeKind || '')
                                }}
                                options={paymentTypes.map(item => ({ value: item.id, label: item.name }))}
                            />
                        )}
                    />
                </div>
            </div>
            <Button loader={loading} onClick={updatePaymentType} variant={EButtonVariant.BLUE}>
                Обновить меню
            </Button>
            <div className='h-full flex items-end pb-12'>
                <AppImage src={Assets.settings} />
            </div>
        </div>
    )
}
