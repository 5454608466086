import { useContextMenu } from 'react-contexify'
import { AppTable, Button, GridTable, GridTableHead, GridTableItem, MediumText, RegularText } from 'src/components'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
    clientListPaginationAtom,
    clientsAtom,
    currentClientAtom,
    dbClientsAtom,
    doubleClickedClientAtom,
    setCurrentClientAtom,
} from 'src/atoms/clientDb/clientsAtom'
import { ClientDB } from 'src/services/LocaliRepository/LocaliRepositorySchemas/ClientDBSchema'
import { tableActiveClassName } from 'src/utils'
import { openEditClientAtom } from 'src/atoms/clientDb/editClientDBAtom'
import React, { useEffect, useState } from 'react'

import getWordForm, { WORDS_LIST } from '../../utils/getWordForm'

import { CLIENT_DATABASE_CTX_ID } from './utils'

const THEADS = [
    'Таб. номер',
    'Телефон',
    'ФИО',
    'Город',
    'Заказ',
    'Общая сумма',
    'Общее количество заказов',
    'Бонусы',
    'Статус активности',
    'Статус блокировки',
    'Комментарий',
]

export const ClientDatabaseTable = (): JSX.Element => {
    // @ts-ignore
    const { data, totalPages, total } = useAtomValue(dbClientsAtom)
    const [pagination, setPagination] = useAtom(clientListPaginationAtom)

    const currentClient = useAtomValue(currentClientAtom)
    const setDoubleClickedClient = useSetAtom(doubleClickedClientAtom)
    const setCurrentClient = useSetAtom(setCurrentClientAtom)
    const openEditModal = useSetAtom(openEditClientAtom)

    const { show } = useContextMenu({
        id: CLIENT_DATABASE_CTX_ID,
    })

    const getClients = useAtomValue(dbClientsAtom)
    const setClients = useSetAtom(clientsAtom)

    useEffect(() => {
        if (getClients?.data) {
            setClients(getClients?.data)
        }
    }, [getClients])

    const renderCellFn = (item: ClientDB): JSX.Element => {
        const isActive = currentClient?.clientData.client_id === item.clientData.client_id
        const TextComponent = isActive ? MediumText : RegularText

        return (
            <tr>
                <GridTableItem
                    child={<TextComponent>{item.clientData.tabNumber || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.phoneNumber || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {`${item.clientData.firstName || ''} ${item.clientData.middleName || ''} ${item.clientData.lastName || ''}`}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.cityKey || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {Number(item.activeOrderCount) ? (
                                <span className='text-orange-500'>
                                    {item.activeOrderCount} {getWordForm(Number(item.activeOrderCount), WORDS_LIST.active)}
                                </span>
                            ) : (
                                'Нет активных'
                            )}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.totalPrice || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.orderCount || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.bonuscount ?? '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>{item.isActive ? <span className='text-orange-500'>Активный</span> : 'Неактивный'}</TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={
                        <TextComponent>
                            {item.clientData.isBanned ? 'Забанен' : <span className='text-orange-500'>Активный</span>}
                        </TextComponent>
                    }
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
                <GridTableItem
                    child={<TextComponent>{item.clientData.comment || '-'}</TextComponent>}
                    onDoubleClick={() => {
                        setCurrentClient({ ...item })
                        openEditModal()
                    }}
                    className={tableActiveClassName(isActive)}
                    onClick={() => setCurrentClient(item)}
                    onContextMenu={e => {
                        setDoubleClickedClient(item)
                        show({ event: e })
                    }}
                />
            </tr>
        )
    }
    const handlePrevClick = () => {
        if (pagination.current > 1) {
            setPagination(pre => ({ ...pre, current: pre.current - 1 }))
        }
    }

    const handleNextClick = () => setPagination(pre => ({ ...pre, current: pre.current + 1 }))

    return (
        <div>
            {/*<AppTable<ClientDB>
        theads={THEADS}
        tbody={data}
        renderCellFn={renderCellFn}
      />*/}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '16px',
                    marginTop: '8px',
                }}>
                <RegularText>Кол-во строк:</RegularText>
                <select
                    className='select'
                    value={pagination.pageSize}
                    //@ts-ignore
                    onChange={e => setPagination({ ...pagination, pageSize: e.target.value, current: 1 })}
                    style={{
                        width: '100px',
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <RegularText>
                    Страница: {pagination.current} - {totalPages}
                </RegularText>
                <Button
                    disabled={pagination.current === 1}
                    onClick={handlePrevClick}
                    style={{
                        fontSize: '8px',
                    }}>
                    ◀
                </Button>
                <Button
                    disabled={pagination.current >= totalPages}
                    onClick={handleNextClick}
                    style={{
                        fontSize: '8px',
                    }}>
                    ▶
                </Button>
            </div>
            <GridTable
                theads={THEADS.map(head => (
                    <GridTableHead key={head} title={head} />
                ))}>
                {!!data?.length && data.map(renderCellFn)}
            </GridTable>
        </div>
    )
}
