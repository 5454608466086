import React, { useCallback, useEffect, useState } from 'react'
import { useSetAtom } from 'jotai'
import moment from 'moment/moment'

import { Button, EButtonVariant, GridTable, GridTableHead, GridTableItem, MediumText, RegularText, TextInput } from '../../../components'
import { CouriersAccounting, CouriersAccountingOrders, FinancesAccounting, FinancesCourierAccounting, LokaliApi } from '../../../services'
import { closeDialogAtom, dialogStateAtom } from '../../../atoms/global/dialogAtom'
import { setCloseAllModals } from '../../../atoms/global/modalAtom'

import styles from '../styles.module.scss'

import { AccountingCouriersDialog } from './AccountingCouriersDialog'

const THEADS = ['Название ресторана', 'Номер заказа', 'Суммма заказа', 'Время заказа']

type Props = {
    courierId: string
    setCouriersAccounting: (accounting: any) => void
    courierAccounting: FinancesCourierAccounting
}
const AccountingCouriersModal = ({ courierId, setCouriersAccounting, courierAccounting }: Props): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const setDialogState = useSetAtom(dialogStateAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const closeAllModals = useSetAtom(setCloseAllModals)
    const [orders, setOrders] = useState<CouriersAccountingOrders>()

    const [valuePaid, setValuePaid] = useState<string>('')

    const fetchOrders = useCallback(async () => {
        try {
            setLoading(true)
            const data: CouriersAccountingOrders = await LokaliApi.getCourierAccountingOrders({ courierfinance_id: courierId })

            setOrders(data)
        } catch (e) {
            console.warn(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const pay = useCallback(async () => {
        try {
            setDialogState({
                isOpen: true,
                showCloseIcon: false,
                children: (
                    <AccountingCouriersDialog
                        loading={true}
                        amount={Number(valuePaid) || 0}
                        onAccept={pay}
                        closeAll={closeAllModals}
                        onAbort={closeAllModals}
                        paymentSuccess={true}
                    />
                ),
            })
            const data: FinancesCourierAccounting = await LokaliApi.payCourierAccountingOrders({
                courierfinance_id: courierId,
                sum: valuePaid || 0,
            })

            if (data?.order_id) {
                setCouriersAccounting(prev =>
                    prev
                        .map(item => (item.courierfinance_id === courierId ? data : item))
                        .sort((a, b) => (Number(a.was_paid) > Number(b.was_paid) ? 1 : -1)),
                )
                setDialogState({
                    isOpen: true,
                    showCloseIcon: false,
                    children: (
                        <AccountingCouriersDialog
                            loading={false}
                            amount={Number(valuePaid) || 0}
                            onAccept={pay}
                            closeAll={closeAllModals}
                            onAbort={closeAllModals}
                            paymentSuccess={true}
                        />
                    ),
                })
            }
        } catch (e) {
            console.warn(e)
        }
    }, [orders, valuePaid])

    const handleCloseDialog = useCallback(() => {
        closeDialog()
    }, [])

    const onPayClick = useCallback(() => {
        setDialogState({
            isOpen: true,
            children: (
                <AccountingCouriersDialog
                    loading={false}
                    amount={Number(valuePaid) || 0}
                    onAccept={pay}
                    closeAll={() => {
                        closeAllModals()
                    }}
                    onAbort={handleCloseDialog}
                    paymentSuccess={false}
                />
            ),
        })
    }, [pay, orders, valuePaid])

    useEffect(() => {
        fetchOrders().then()
    }, [])

    return (
        <div>
            <div className={styles.topRow}>
                <div>
                    <MediumText>К оплате:</MediumText>
                    <RegularText style={{ marginLeft: 4 }}>{courierAccounting.totalPayment}</RegularText>
                </div>

                <div className='flex gap-1 items-center'>
                    <MediumText>ЗП курьеру:</MediumText>
                    <TextInput
                        max={courierAccounting?.totalPayment}
                        value={valuePaid}
                        onChange={e => setValuePaid(e.target.value)}
                        style={{ maxWidth: '220px' }}
                        type='number'
                        placeholder='0.00 рублей'
                    />

                    <Button
                        disabled={Number(valuePaid) < 0 || courierAccounting?.totalPayment < 0}
                        onClick={onPayClick}
                        variant={EButtonVariant.GREEN}>
                        <MediumText>Провести инкассацию</MediumText>
                    </Button>
                </div>
            </div>

            <div className={styles.modalGridWrapper}>
                <GridTable
                    theads={THEADS.map(head => (
                        <GridTableHead key={head} title={head} />
                    ))}>
                    {orders &&
                        orders.ordersArray?.map((orderGroup, id) => (
                            <tr key={id}>
                                <GridTableItem child={orderGroup.restName} className={styles.cell} />
                                <GridTableItem child={orderGroup.orderNumber} className={styles.cell} />
                                <GridTableItem child={orderGroup.orderPayment} className={styles.cell} />
                                <GridTableItem
                                    child={
                                        !!orderGroup.orderTime &&
                                        moment(orderGroup.orderTime.replace('Z', '')).add('3', 'hour').format('HH:mm DD.MM.YYYY')
                                    }
                                    className={styles.cell}
                                />
                            </tr>
                        ))}
                </GridTable>
            </div>
        </div>
    )
}

export default AccountingCouriersModal
