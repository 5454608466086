import { HeaderLayout, SuspenseWithSpinner, BaseLayout, SelectOption } from 'src/components'
import { AllOrdersCouriersList, AllOrderTableBody, AllOrderAssignCourier, AllOrdersFilters } from 'src/features/Orders'
import { Suspense, useEffect, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/index'

import { adminsListAtom, getAdminsAtom } from '../../atoms/admins/adminsTableAtom'
import { getOrdersAtom, ordersListAtom } from '../../atoms/allOrders/ordersAtom'
import { getRestAtom, restsAtom } from '../../atoms/restaraunts/restAtom'

import styles from './styles.module.scss'

export const AllOrdersPage = (): JSX.Element => {
    const [selectedType, setSelectedType] = useState<SelectOption>({ value: 'Активные', label: 'Активные' })

    return (
        <div className='h-full w-full'>
            <BaseLayout
                //@ts-ignore
                filtersChild={<AllOrdersFilters selectedType={selectedType} setSelectedType={setSelectedType} />}
                leftChild={
                    <div className={styles.leftChild}>
                        <HeaderLayout title='Заказы' />
                        <AllOrderAssignCourier />
                        <SuspenseWithSpinner>
                            <AllOrdersCouriersList />
                        </SuspenseWithSpinner>
                    </div>
                }>
                <Suspense>
                    <Comp />
                </Suspense>
                {/*@ts-ignore*/}
                <AllOrderTableBody selectedType={selectedType} />
            </BaseLayout>
        </div>
    )
}

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getOrders = useAtomValue(getOrdersAtom)
    const getRests = useAtomValue(getRestAtom)
    const setOrders = useSetAtom(ordersListAtom)
    const setRests = useSetAtom(restsAtom)

    useEffect(() => {
        if (getOrders?.data) {
            setOrders(getOrders?.data)
        }
        if (getRests?.data) {
            // we use rests in orders filters
            setRests(getRests?.data)
        }
    }, [getOrders, getRests])

    return <></>
}
